<template>
  <div>
      <van-overlay :show="show" @click="show = false" />
      <div class="cropper-section" v-if="show">
            <cropper
                class="cropper"
                :src="img"
                :stencil-props="{
                    aspectRatio: 10/12
                }"
                @change="change"
            />          
      </div>
    <van-button type="primary" @click="show=true">主要按钮</van-button>
  </div>
</template>

<script setup>
import { ref } from 'vue'

import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';


const img = ref('https://images.unsplash.com/photo-1600984575359-310ae7b6bdf2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=700&q=80')

const show = ref(false)

const change = ({ coordinates, canvas }) => {
    console.log(coordinates, canvas)
}

</script>

<style lang="less" scoped>
.cropper-section{
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    z-index: 8888;
    background-color: #666;
}

</style>