<template>
  <div class="login-container">
        <div class="box">
            <div class="title">
                UTel電子名片系統
            </div>
            <div class="main-section" @click="handleLogin">
                    <button type="submit" class="btn">LINE登 入</button>
            </div>
            <div class="footer">
                copyright 2022
            </div>
        </div>
  </div>
</template>

<script setup>
import store from '@/store'

import router from '@/router'

import { Toast } from 'vant'

import { login } from '@/api'

import { useRoute } from 'vue-router'

const route = useRoute()


const handleLogin = async()=>{
    router.push('/')
}

const handleLogin1 = async()=>{
    try{
        await liff.ready

        if (!liff.isLoggedIn()){
            liff.login({ redirectUri: window.location.href + '/login' })
        }

        const profile = await liff.getProfile()
        
        const id_token = liff.getIDToken()

        let loginRes = await login({line_id: profile.userId, token: id_token})
        
        if(loginRes.code===200){
            // console.log('data',loginRes.data)
            sessionStorage.setItem('token',loginRes.data.token)
            sessionStorage.setItem('uid',loginRes.data.uid)
            Toast('登入成功')
            // return router.push('/')
        }else if(loginRes.code===201){
            Toast('請先註冊成為本站會員')
            return router.push('/register')
        }

        Toast('登入失敗。請聯絡管理員')
        // console.log(loginRes)

    //   let checkLine = await checkLineId(profile.userId)

    //   if(checkLine.code!==200){
    //     return router.push('/register')
    //   }
    //   store.commit('user/setUserInfo',checkLine.data.data)
    //   return router.push('/home')
    }catch( err ){
        alert(err)
        console.log(`liff.state init error ${err}`)
    }

}
</script>

<style lang="less" scoped>
*{
    font-family: 'Inter',Arial, Helvetica, sans-serif;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
}
*::selection{
    background-color: #c7c9ca;
}
body{
    background-color: #ecf0f3;
    margin: 0px;
    padding: 0px;
}
.lead { font-size: 1.5rem; font-weight: 300; }
.container { margin: 150px auto; max-width: 960px; }
.box{
    margin: auto;
    box-sizing: border-box;
    margin-top: 100px;
    height:250px;
    padding: 80px 40px;
    width:340px;
    border-radius: 35px;
    background-color: #ecf0f3;
    box-shadow: -8px -8px 8px #feffff, 8px 8px 8px #161b1d2f;
    /* box-shadow: -5px -5px 5px #feffff, 5px 5px 5px #161b1d2f; */
    /* box-shadow: inset 5px 5px 5px #cbced1,
        inset -5px -5px 5px #ffffff; */
}
/* .box:hover{
    box-shadow: inset 5px 5px 5px #cbced1,
        inset -5px -5px 5px #ffffff;
} */
.box .title{
    font-size: 24px;
    font-weight: 500;
    color:#858686;
    text-align: center;
    /* margin-bottom: 10px; */
}

.box .footer{
    font-size: 12px;
    font-weight: 500;
    line-height: 100px;
    color:#858686;
    text-align: center;
    /* margin-bottom: 10px; */
}

.box label{
    font-size: 16px;
    font-weight: 500;
    color:#858686;
    /* margin-bottom: 10px; */
}
.box .input-box{
    width:100%;
    height: 35px;
    padding-left: 20px;
    border: none;
    color:#858686;
    margin-top: 10px;
    background-color: #ecf0f3;
    outline: none;
    border-radius: 20px;
    box-shadow: inset 5px 5px 5px #cbced1,
        inset -5px -5px 5px #ffffff;
}
.box .input-box::placeholder{
    color:#9ea0a0;
}
.forget{
    margin-top:15px;
}
.forget .fg a{
    text-decoration: none;
    font-size: 13px;
    color:#8f8c8c;
    line-height: 24px;
}
.forget .fg{
    display: inline-block;
    margin-left: 58%;
    font-size: 12px;
}
.forget .fg:hover{
    text-decoration: underline;
    text-decoration-color: #b4b8b8;
}
.forget .checkbox-label{
    display:block;
    position: relative;
    cursor: pointer;
    font-size:22px;
    line-height: 22px;
}
.label-text{
    color:#8f8c8c;
    display: inline-block;
    /* width: 100%; */
    position: absolute;
    font-weight: 500;
    left:12%;
    font-size: 13px;
}
.forget .checkbox-label input{
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkbox-label .checkbox-custom{
    position: absolute;
    top:0;
    left:0px;
    height:20px;
    width:20px;
    background-color: #ecf0f3;
    border-radius: 5px;
    border: none;
    box-shadow:inset 3px 3px 3px #cbced1, inset -3px -3px 3px #ffff;
}
.checkbox-label input:checked ~ .checkbox-custom{
    background-color: #ecf0f3;
    border-radius: 5px;
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform:rotate(0deg) scale(1);
    opacity: 1;
    border:none;
    box-shadow: -4px -4px 4px #feffff, 4px 4px 4px #161b1d2f;
}
.checkbox-label .checkbox-custom::after{
    position: absolute;
    content:"";
    left:10px;
    top:10px;
    height:0px;
    width:0px;
    border-radius:5px;
    border:solid #635f5f;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(0deg) scale(0);
    -ms-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity:1;
    transition: all 0.3s ease-out;
}
.checkbox-label input:checked ~ .checkbox-custom::after {
    -webkit-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    opacity:1;
    left: 7px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid #635f5f;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
    
  }
.btn{
    width:100%;
    margin-top: 20px;
    height: 38px;
    border: none;
    outline: none;
    border-radius: 20px;
    background-color: #57BA18;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
    box-shadow: -5px -5px 8px #d8e2e6, 5px 5px 10px #2c313378;
    transition: 0.8s;
}
.btn:hover{
    background-color: #535658;
    box-shadow: inset 5px 5px 10px #05050578,
        inset -5px -5px 10px #9e9c9c;
}
.social{
    display: flex;
    justify-content: center;
    margin-top: 14px;
}
.box-radius{
    border-radius:50%;
    width:40px;
    display: block;
    height:40px;
    margin: 6px;
    /* margin-top: 50px; */
    background-color: #ecf0f3;
    box-shadow: 5px 5px 6px #0d275023,-5px -5px 6px #ffffff;
    padding: 11px;
    cursor: pointer;
}
.box-radius:hover{
    box-shadow: inset 5px 5px 5px #cbced1,
        inset -5px -5px 5px #ffffff;
}
.box-radius img{
    width: 18px;
    margin: auto;
    height: 18px;
}
.option{
    display: block;
    margin-top: 35px;
    color: #6c6d6d;
    text-align: center;
}

</style>